import Immutable from 'immutable';
import { CALL_API } from 'redux-api-middleware';
import queryString from 'query-string';
import moment from 'moment';
import { urlDate } from 'components/Helpers';

// import AnalyticsService from 'components/Analytics/AnalyticsService';
import { DEALS_API_ENDPOINT } from 'constants/endpoints';

const initialFilters = Immutable.Map({
  region: '',
  origin_code: '',
  long_weekend: null,
  max_stops: '',
  max_stay: null,
  min_stay: null,
  min_check_in: urlDate(moment()),
  max_check_in: urlDate(moment().add(60, 'days')),
  max_price: 10000,
  alliance: '',
  cabin_class: '',
});

export const initialState = Immutable.fromJS({
  loading: false,
  loaded: false,
  items: {},
  queries: Immutable.Map(),
  filters: initialFilters,
  activeFilters: initialFilters,
});

// ACTION TYPES
const REQUEST = 'skylark/deals/REQUEST';
const SUCCESS = 'skylark/deals/SUCCESS';
const FAILURE = 'skylark/deals/FAILURE';

const SET_FILTER = 'skylark/deals/SET_FILTER';
const CHANGE_FILTERS = 'skylark/deals/CHANGE_FILTERS';
const SET_FILTERS = 'skylark/deals/SET_FILTERS';
const CLEAR_FILTERS = 'skylark/deals/CLEAR_FILTERS';

export const hasChanged = state => {
  const applied = state.deals.get('activeFilters');
  const controls = state.deals.get('filters');
  const dirty = !applied.equals(controls);
  return dirty;
};

export const filterQuery = filters => {
  let cleanParams = Immutable.Map();
  filters.map((value, key) => {
    if (value) {
      cleanParams = cleanParams.set(key, value);
    }
  });
  const sorted = cleanParams.toOrderedMap().sortBy((v, k) => k);
  return queryString.stringify(sorted.toJS());
};

// ACTION CREATORS
function request(endpoint, query) {
  return {
    [CALL_API]: {
      endpoint,
      method: 'GET',
      types: [
        { type: REQUEST, meta: { query } },
        { type: SUCCESS, meta: { query } },
        { type: FAILURE, meta: { query } },
      ],
      bailout: state => state.deals.getIn(['queries', query]),
    },
  };
}

export function requestDeals(params = {}) {
  const query = filterQuery(params);
  const urlQueryString = query ? `${query}&max_results=50` : 'max_results=50';

  const endpoint = `${DEALS_API_ENDPOINT}/pairings?${urlQueryString}`;
  return request(endpoint, query);
}

export function requestDealsSummary() {
  const query = 'summary';
  const endpoint = `${DEALS_API_ENDPOINT}/pairings/summary.json`;
  return request(endpoint, query);
}

export const setFilter = (filter, value) => ({ type: SET_FILTER, filter, value });

export const changeFilters = filters => ({ type: CHANGE_FILTERS, filters });

export const setFilters = filters => ({ type: SET_FILTERS, filters });

export const clearFilters = () => ({ type: CLEAR_FILTERS });

// REDUCER HELPER METHODS
const dealsRequested = (state, query) => state.setIn(['queries', query, 'isLoading'], true);

const dealsReceived = (state, payload, query) =>
  state
    .setIn(['queries', query, 'items'], Immutable.fromJS(payload))
    .setIn(['queries', query, 'isLoading'], false)
    .setIn(['queries', query, 'isLoaded'], true);

const requestFailed = (state, query) =>
  state.setIn(['queries', query, 'isLoading'], false).setIn(['queries', query, 'isLoaded'], false);

const onSetFilters = (state, filters = Immutable.Map()) => {
  let newState = state;
  filters.forEach((value, key) => {
    newState = newState.setIn(['filters', key], value).setIn(['activeFilters', key], value);
  });
  return newState;
};

const onChangeFilters = (state, filters = Immutable.Map()) => onSetFilters(state, filters);

const onClearFilters = state =>
  // AnalyticsService.track('Deals: Clear Filters');
  state.set('filters', initialFilters).set('activeFilters', initialFilters);

// REDUCER

export default function deals(state = initialState, action) {
  switch (action.type) {
    case REQUEST:
      return dealsRequested(state, action.meta.query);
    case SUCCESS:
      return dealsReceived(state, action.payload, action.meta.query);
    case FAILURE:
      return requestFailed(state, action.meta.queryString);
    case SET_FILTER:
      return state.setIn(['filters', action.filter], action.value);
    case CHANGE_FILTERS:
      return onChangeFilters(state, action.filters);
    case SET_FILTERS:
      return onSetFilters(state, action.filters);
    case CLEAR_FILTERS:
      return onClearFilters(state);
    default:
      return state;
  }
}

import Immutable from 'immutable';
import moment from 'moment';
import axios from 'axios';

import { AuthHeaders } from 'modules/authentication/authentication';
import { BOOKING_API_ENDPOINT } from 'constants/endpoints';

export const initialState = Immutable.fromJS({});

// ACTION TYPES
const REQUEST = 'skylark/vaultToken/REQUEST';
const SUCCESS = 'skylark/vaultToken/SUCCESS';
const FAILURE = 'skylark/vaultToken/FAILURE';

const fetchAuricVaultSession = async () => {
  const resourceUrl = `${BOOKING_API_ENDPOINT}/vault_session`;

  try {
    const results = await axios.get(resourceUrl, { headers: AuthHeaders() });
    console.log('fetchAuricVaultSession > success', results.data);
    const sessionId = results.data.session_id;
    const targetUrl = results.data.target_url;
    return {
      status: 'success',
      sessionId,
      targetUrl,
    };
  } catch (e) {
    // console.log('fetchAuricVaultSession > error', e.response);
    const respStatus = e.response ? { status: e.response.status, message: e.response.statusText } : null;
    return respStatus;
  }
};

export const fetchAuricVaultToken = async pan => {
  const { status, sessionId, targetUrl, message } = await fetchAuricVaultSession();
  if (status !== 'success') {
    console.log('fetchAuricVaultSession: FAILED', message);
    // TODO: Sesssion could not be created - handle error messaging
    return false;
  }
  console.log('Auric sessionId', targetUrl, sessionId);
  const requestPayload = {
    params: [
      {
        sessionId,
        last4: '',
        plaintextValue: pan,
        utcTimestamp: moment()
          .unix()
          .toString(),
      },
    ],
    id: 1,
    method: 'session_encrypt',
  };
  try {
    const results = await axios.post(targetUrl, requestPayload);
    return results.data.result.token;
  } catch (e) {
    const respStatus = e.response ? e.response.status : null;
    return respStatus;
  }
};


export const fetchVgsVaultToken = async (pan) => {
  const resourceUrl = `${BOOKING_API_ENDPOINT}/vgs_vault/redact`;
  const requestPayload = { pan: pan };
  try {
    const results = await axios.post(resourceUrl, requestPayload);
    // console.log('fetchVgsVaultToken > results', results);
    return results.data.token;
  } catch (e) {
    const respStatus = e.response ? e.response.status : null;
    return respStatus;
  }
};

// REDUCER HELPER METHODS
const requested = state => state.set('loading', true).set('loaded', false);

const received = (state, action) =>
  state
    .set('vaultToken', action.payload.result.token)
    .set('loading', false)
    .set('loaded', true);

const failed = (state, action) =>
  state
    .set('error', Immutable.fromJS(action.payload))
    .set('loading', false)
    .set('loaded', false);

// REDUCER
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return requested(state, action);
    case SUCCESS:
      return received(state, action);
    case FAILURE:
      return failed(state, action);
    default:
      return state;
  }
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import track from 'react-tracking';
import { Router, useRouter } from 'next/router';
import { PageLayout } from 'components/UI';
import { useClientConfig, useProfile } from 'hooks';
import PageTracking from './PageTracking';

const PageWrapper = ({ layout, trackingOptions, layoutOptions, children }) => {
  const clientConfig = useClientConfig();
  const router = useRouter();
  const [currentUrl, setCurrentUrl] = useState(router.asPath);
  const { user } = useProfile();

  Router.events.on('routeChangeComplete', (url) => {
    setCurrentUrl(url);
  });

  return (
    <PageLayout {...layout} layoutOptions={layoutOptions} user={user} clientConfig={clientConfig}>
      <PageTracking url={currentUrl} trackingOptions={trackingOptions} />
      <div className="website-page">{children}</div>
    </PageLayout>
  );
};

PageWrapper.propTypes = {
  layout: PropTypes.instanceOf(Object),
  layoutOptions: PropTypes.instanceOf(Object),
  trackingOptions: PropTypes.instanceOf(Object),
  children: PropTypes.node,
  userAgent: PropTypes.instanceOf(Object),
};

const segmentTrack = (data) => {
  const { event } = data;
  // console.log('PageWrapper > segmentTrack > event', event, data);
  delete data.event;
  window.analytics.track(event, data);
};

export default track({ source: 'next', env: process.env.NODE_ENV }, { dispatch: (data) => segmentTrack(data) })(PageWrapper);

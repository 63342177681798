// import moment from 'moment';
import Immutable from 'immutable';
import { CALL_API } from 'redux-api-middleware';
// import Cookies from 'js-cookie';

import { CONTENT_API_ENDPOINT as API_BASE } from 'constants/endpoints';

const CREATE_MEMBERSHIP_REQUEST = 'skylark/membership/CREATE_MEMBERSHIP_REQUEST';
const CREATE_MEMBERSHIP_SUCCESS = 'skylark/membership/CREATE_MEMBERSHIP_SUCCESS';
const CREATE_MEMBERSHIP_FAILURE = 'skylark/membership/CREATE_MEMBERSHIP_FAILURE';

const initialState = Immutable.fromJS({
  loading: false,
  loaded: false,
  processing: false,
  status: 'new',
  errors: null,
});

export function createMembership(request) {
  // const request = {};
  console.log('createMembership', request);
  return {
    [CALL_API]: {
      endpoint: `${API_BASE}/memberships`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ membership: request }),
      types: [CREATE_MEMBERSHIP_REQUEST, CREATE_MEMBERSHIP_SUCCESS, CREATE_MEMBERSHIP_FAILURE],
    },
  };
}

const membershipCreateSuccess = (state, payload) => state;

const membershipCreateFailure = (state, payload) =>
  state
    .set('status', 'error')
    .set('loading', false)
    .set('loaded', false)
    .set('errors', Immutable.fromJS(payload.response.errors));

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_MEMBERSHIP_REQUEST:
      return state;
    case CREATE_MEMBERSHIP_SUCCESS:
      return membershipCreateSuccess(state, action.payload);
    case CREATE_MEMBERSHIP_FAILURE:
      return membershipCreateFailure(state, action.payload);
    default:
      return state;
  }
};

export default reducer;

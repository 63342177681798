import { createStore as _createStore, combineReducers, applyMiddleware } from 'redux';
import { apiMiddleware } from 'redux-api-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
// import { createBrowserHistory } from 'history';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';

import thunk from 'redux-thunk';
// import Immutable from 'immutable';

import { persistStore, persistReducer } from 'redux-persist';
import persistConfig from './persistConfig';

import ducks from '../modules/index';
import authInjector from './middleware/authInjector';

const rootReducer = persistReducer(persistConfig, combineReducers(ducks));

const getMiddleWare = () => {
  const isProdEnv = process.env.NODE_ENV !== 'production';
  return isProdEnv ? [thunk, authInjector, apiMiddleware] : [thunk, authInjector, apiMiddleware];
};

export const modules = {};

const reducer = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
    };
    // if (state.count.count) nextState.count.count = state.count.count; // preserve count value on client side navigation
    return nextState;
  }
  return rootReducer(state, action);
};

const bindMiddleware = middleware => {
  if (process.env.NODE_ENV !== 'production') {
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const initStore = () => _createStore(reducer, undefined, bindMiddleware(getMiddleWare()));

const makeStore = () => {
  const isServer = typeof window === 'undefined';

  if (isServer) {
    return initStore(reducer);
  }
  // we need it only on client side
  const persistedReducer = persistReducer(persistConfig, reducer);
  const store = initStore(persistedReducer);

  store.__persistor = persistStore(store); // Nasty hack

  return store;
};

export const wrapper = createWrapper(makeStore);

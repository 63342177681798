import Immutable from 'immutable';
import { CALL_API } from 'redux-api-middleware';

import { CONTENT_API_ENDPOINT, DESTINATION_API_ENDPOINT, requestHeaders } from 'constants/endpoints';

export const fetchDestination = async destinationPath => {
  console.log('fetchDestination', { destinationPath });
  const resourceUrl = destinationPath
    ? `${DESTINATION_API_ENDPOINT}/destinations/${destinationPath}`
    : `${DESTINATION_API_ENDPOINT}/destinations`;
  const res = await fetch(resourceUrl, requestHeaders);
  const destination = await res.json();
  return destination;
};

export const initialState = Immutable.fromJS({
  loading: false,
  loaded: false,
  items: {},
});

// ACTION TYPES
const REQUEST = 'skylark/destination/REQUEST';
const REQUEST_ALL = 'skylark/destination/REQUEST_ALL';
const CLEAR_ALL = 'skylark/destination/CLEAR_ALL';
const SUCCESS = 'skylark/destination/SUCCESS';
const SUCCESS_ALL = 'skylark/destination/SUCCESS_ALL';
const FAILURE = 'skylark/destination/FAILURE';

// ACTION CREATORS
export const requestAllDestinations = (query = '') => {
  const endPoint = `${CONTENT_API_ENDPOINT}/destinations/${query}`;
  return {
    [CALL_API]: {
      endpoint: endPoint,
      method: 'GET',
      types: [REQUEST_ALL, SUCCESS_ALL, FAILURE],
      bailout: state => state.destinations.get('loaded'),
    },
  };
};

export const clearAllDestinations = () => ({ type: CLEAR_ALL });

const shouldBailout = (state, query) => {
  const destination = state.destinations.getIn(['items', query]) || Immutable.Map({});
  const isLoading = destination.get('loading') || false;
  const isDeepLoaded = !!destination.get('hotels');
  const validQuery = query.length >= 3;
  const doBailout = !validQuery || isLoading || isDeepLoaded;
  return doBailout;
};

export const requestDestination = (query = '') => {
  const endpoint = `${CONTENT_API_ENDPOINT}/destinations/${query}`;
  return {
    [CALL_API]: {
      endpoint,
      method: 'GET',
      types: [
        {
          type: REQUEST,
          meta: { code: query },
        },
        {
          type: SUCCESS,
          meta: { code: query },
        },
        {
          type: FAILURE,
          meta: { code: query },
        },
      ],
      bailout: state => shouldBailout(state, query),
    },
  };
};

// REDUCER METHODS
const allDestinationsRequested = state =>
  state
    .set('loading', true)
    .set('loaded', false)
    .set('items', Immutable.fromJS({}));

export const destinationRequested = (state, code) =>
  state.setIn(['items', code, 'loading'], true).setIn(['items', code, 'loaded'], false);

const destinationReceived = (payload, state, code) => {
  const destination = Immutable.fromJS(payload)
    .set('loaded', true)
    .set('loading', false);
  return state.setIn(['items', code], destination);
};

const cleanDestinations = destinations =>
  destinations
    .filter(destination => !!destination.get('access_code'))
    .filter(destination => !!destination.get('hotel_count'));

const keyedDestinations = destinations =>
  Immutable.Map(cleanDestinations(destinations).map(v => [v.get('access_code'), v]));

// Convert Hotels Array into code keyed, Immutable Map
const createHotelsMap = hotels => {
  if (!hotels) {
    return null;
  }
  return Immutable.Map(hotels.map(hotel => [hotel.get('code'), hotel]));
};

// Convert Destinations Array into iata_code keyed, Immutable Map
const createDestinationsMap = destinations => {
  const dests = keyedDestinations(destinations);
  return dests.map(destination => {
    const hotelMap = createHotelsMap(destination.get('hotels'));
    return destination.set(
      'hotels',
      hotelMap.sortBy(hotel => parseInt(hotel.get('lead_hotel_price') || 999999, 10))
    );
  });
};

const allDestinationsReceived = (destinations, state) => {
  const destinationsMap = createDestinationsMap(Immutable.fromJS(destinations));
  const items = state.get('items').mergeDeep(destinationsMap);
  return state
    .set('items', items)
    .set('loaded', true)
    .set('loading', false);
};

// REDUCER
export default function reducer(state = initialState, action = {}) {
  const code = action.meta ? action.meta.code : null;
  switch (action.type) {
    case REQUEST_ALL:
      return allDestinationsRequested(state);
    case REQUEST:
      return destinationRequested(state, code);
    case SUCCESS_ALL:
      return allDestinationsReceived(action.payload, state);
    case CLEAR_ALL:
      return initialState;
    case SUCCESS:
      // return destinationsReceived([action.payload], state, code);
      return destinationReceived(action.payload, state, code);
    case FAILURE:
      return state.setIn(['items', code, 'loading'], false).setIn(['items', code, 'loaded'], false);
    default:
      return state;
  }
}

import activationFlowReducer from './workflow/activation'; // UI STATE / WORKFLOW STORE
import airfareReducer from './airfare';
import availabilityReducer from './availability';
import airportsReducer from './airports';
import airlinesReducer from './airlines';
import alertsReducer from './alerts';
import authenticationReducer from './authentication/authentication';
import bookingCartReducer from './bookingCart';
// import bookingReducer from './booking';
import bookingsReducer from './bookings';
import clientConfigReducer from './clientConfig';
import collectionsReducer from './collections';
// import creditCardReducer from './creditCard';
import currencyReducer from './currency';
import dealsReducer from './deals';
import destinationsReducer from './destinations';
import featuredContentItemsReducer from './featuredContentItems';
import flightsFilterReducer from './flightsFilter';
import hotelAvailabilityReducer from './hotelAvailability';
import hotelDealsReducer from './hotelDeals';
import hotelsReducer from './hotels';
import hotelSummaryReducer from './hotelSummary';
import inquiryReducer from './inquiry';
import invitationsReducer from './invitations';
import membershipReducer from './membership';
import membershipApplicationReducer from './membershipApplication';
import modalReducer from './modals';
// import multiSearchReducer from './multiSearch';
import navigationReducer from './navigation';
// import originSearchReducer from './originSearch';
import paymentMethodsReducer from './paymentMethods';
import paymentsReducer from './payments';
import prospectReducer from './prospect';
import placeReducer from './places';
import referralsReducer from './referrals';
import referrerReducer from './referrer';
import scrollPositionReducer from './scrollPosition';
import settingsReducer from './settings';
import sherpaRestrictionsReducer from './sherpaRestrictions';
import siteConfigReducer from './siteConfig';
import tripSearchReducer from './tripSearch';
import tripSelectionReducer from './tripSelection';
import userReducer from './user';
import vaultSessionReducer from './vaultSession';
import vaultTokenReducer from './vaultToken';
import watchListReducer from './watchList';
import agentLinkEmailReducer from './agentLinkEmail';

const reducers = {
  activationFlow: activationFlowReducer,
  airfare: airfareReducer,
  airlines: airlinesReducer,
  airports: airportsReducer,
  alerts: alertsReducer,
  authentication: authenticationReducer,
  availability: availabilityReducer,
  // booking: bookingReducer,
  bookingCart: bookingCartReducer,
  bookings: bookingsReducer,
  clientConfig: clientConfigReducer,
  collections: collectionsReducer,
  currency: currencyReducer,
  // creditCard: creditCardReducer,
  deals: dealsReducer,
  destinations: destinationsReducer,
  featuredItems: featuredContentItemsReducer,
  flightsFilter: flightsFilterReducer,
  hotelAvailability: hotelAvailabilityReducer,
  hotelDeals: hotelDealsReducer,
  hotels: hotelsReducer,
  hotelSummaries: hotelSummaryReducer,
  inquiry: inquiryReducer,
  invitations: invitationsReducer,
  membership: membershipReducer,
  membershipApplication: membershipApplicationReducer,
  modals: modalReducer,
  // multiSearch: multiSearchReducer,
  navigation: navigationReducer,
  // originSearch: originSearchReducer,
  paymentMethods: paymentMethodsReducer,
  payments: paymentsReducer,
  place: placeReducer,
  prospect: prospectReducer,
  referrals: referralsReducer,
  referrer: referrerReducer,
  scrollPosition: scrollPositionReducer,
  settings: settingsReducer,
  sherpaRestrictions: sherpaRestrictionsReducer,
  siteConfig: siteConfigReducer,
  tripSearch: tripSearchReducer,
  tripSelection: tripSelectionReducer,
  user: userReducer,
  vaultSession: vaultSessionReducer,
  vaultToken: vaultTokenReducer,
  watchList: watchListReducer,
  agentLinkEmail: agentLinkEmailReducer,
};

export default reducers;

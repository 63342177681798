import Immutable from 'immutable';
import { CALL_API } from 'redux-api-middleware';
import { CONTENT_API_ENDPOINT } from 'constants/endpoints';

const initialState = Immutable.fromJS({
  status: null,
  booking: {},
});

const CREATE_PAYMENT_REQUEST = 'skylark/payments/CREATE_PAYMENT_REQUEST';
const CREATE_PAYMENT_SUCCESS = 'skylark/payments/CREATE_PAYMENT_SUCCESS';
const CREATE_PAYMENT_FAILURE = 'skylark/payments/CREATE_PAYMENT_FAILURE';

export const createPayment = (userId, amount, paymentMethodId, bookingId, ccNumber) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({
      [CALL_API]: {
        endpoint: `${CONTENT_API_ENDPOINT}/payments/make_payment`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          user_id: userId,
          charge_amount: Math.round(amount, 2),
          payment_method_id: paymentMethodId,
          booking_id: bookingId,
          process_booking_request: true,
          cc_number: ccNumber,
        }),
        types: [
          CREATE_PAYMENT_REQUEST,
          {
            type: CREATE_PAYMENT_SUCCESS,
            payload: (action, state, res) =>
              res.json().then(json => {
                // console.log('CREATE_PAYMENT_SUCCESS json ', json);
                resolve(json);
                return json;
              }),
          },
          CREATE_PAYMENT_FAILURE,
        ],
      },
    });
  });

const bookingPaymentRequested = (payload, state) => state.set('status', 'processing');

const bookingPaymentSuccess = (payload, state) => {
  // console.log('PAYLOAD', payload);
  const booking = Immutable.fromJS(payload.booking);
  return state.set('record_locator', booking.get('record_locator')).set('status', 'complete');
};

const bookingPaymentFailed = (payload, state) =>
  state.set('errors', Immutable.fromJS(payload.response.errors)).set('status', 'error');

const reducer = (state = initialState, action) => {
  // console.log('ACTION', action);
  switch (action.type) {
    case CREATE_PAYMENT_REQUEST:
      return bookingPaymentRequested(action.payload, state);
    case CREATE_PAYMENT_SUCCESS:
      return bookingPaymentSuccess(action.payload, state);
    case CREATE_PAYMENT_FAILURE:
      return bookingPaymentFailed(action.payload, state);
    default:
      return state;
  }
};

export default reducer;

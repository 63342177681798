import { CALL_API } from 'redux-api-middleware';

import { getAuthToken } from 'constants/config';

const jwtInjector = () => next => action => {
  const callApi = action[CALL_API];
  if (callApi) {
    callApi.headers = Object.assign({}, callApi.headers);
    callApi.headers.Authorization = getAuthToken();
  }
  return next(action);
};

export default jwtInjector;

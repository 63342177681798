import storageSession from 'redux-persist/lib/storage/session';
import immutableTransform from 'redux-persist-transform-immutable';

const reducerWhitelist = ['tripSelection', 'booking', 'settings', 'bookingCart'];

const persistConfig = {
  storage: storageSession,
  // key: 'skylark-2019-09-05',
  key: 'skylark-2020-11-12',
  whitelist: reducerWhitelist,
  transforms: [
    immutableTransform({
      whitelist: reducerWhitelist,
    }),
  ],
};

export default persistConfig;

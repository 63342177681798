import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
 
  body {
    margin: 0;
    font-size: 16px;
    font-family: ${props => props.theme.fonts.body};
    background: ${props => props.theme.colors.neutral2};
    min-height: 100vh;
  }

  #__next {
    height: 100%;
    min-height: 100%;
  }

  h2 {}

  ul {
    list-style: none;
    padding: 0;
  }

  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .dim {
    color: ${props => props.theme.colors.neutral50};
  }

  .link {
    cursor: pointer;
    color: ${props => props.theme.colors.link};
    &.disabled {
      cursor: auto;
      color: ${props => props.theme.colors.neutral50};
    }
  }
`;

export default GlobalStyle;

import Immutable from 'immutable';
import { CALL_API } from 'redux-api-middleware';
import queryString from 'query-string';
import moment from 'moment';
import { urlDate } from 'components/Helpers';

import AnalyticsService from 'components/Analytics/AnalyticsService';
import { DEALS_API_ENDPOINT } from 'constants/endpoints';

const initialFilters = Immutable.Map({
  region: '',
  min_stay: 2,
  max_stay: 7,
  free_nights: 0,
  amenities: 0,
  min_check_in: urlDate(moment().add(7, 'days')),
  max_check_in: urlDate(moment().add(90, 'days')),
});

const defaultFilters = Immutable.Map({
  region: '',
  min_stay: 1,
  max_stay: 14,
  free_nights: 0,
  amenities: 0,
  min_check_in: urlDate(moment().add(1, 'days')),
  max_check_in: urlDate(moment().add(180, 'days')),
});

export const initialState = Immutable.fromJS({
  loading: false,
  loaded: false,
  items: {},
  queries: Immutable.Map(),
  defaultFilters,
  filters: initialFilters,
  activeFilters: initialFilters,
});

// ACTION TYPES
const REQUEST = 'skylark/hotelDeals/REQUEST';
const SUCCESS = 'skylark/hotelDeals/SUCCESS';
const FAILURE = 'skylark/hotelDeals/FAILURE';

const SET_FILTERS = 'skylark/hotelDeals/SET_FILTERS';
const RESET_FILTERS = 'skylark/hotelDeals/RESET_FILTERS';

export const hasFilters = state => {
  const applied = state.hotelDeals.get('filters');
  const controls = initialFilters;
  const dirty = !applied.equals(controls);
  return dirty;
};

export const hasChanged = state => {
  const applied = state.hotelDeals.get('activeFilters');
  const controls = state.hotelDeals.get('filters');
  const dirty = !applied.equals(controls);
  return dirty;
};

export const filterQuery = filters => {
  let cleanParams = Immutable.Map();
  filters.map((value, key) => {
    if (value) {
      cleanParams = cleanParams.set(key, value);
    }
  });
  const sorted = cleanParams.toOrderedMap().sortBy((v, k) => k);
  return queryString.stringify(sorted.toJS());
};

// ACTION CREATORS
function request(endpoint, query) {
  return {
    [CALL_API]: {
      endpoint,
      method: 'GET',
      types: [
        { type: REQUEST, meta: { query } },
        { type: SUCCESS, meta: { query } },
        { type: FAILURE, meta: { query } },
      ],
      bailout: state => state.hotelDeals.getIn(['queries', query]),
    },
  };
}

export function requestDeals(params = {}) {
  const query = filterQuery(params);
  const endpoint = `${DEALS_API_ENDPOINT}/hotel_deals?${query}`;
  return request(endpoint, query);
}

export function requestDealsSummary() {
  const query = 'summary';
  const endpoint = `${DEALS_API_ENDPOINT}/hotel_deals/summary.json`;
  return request(endpoint, query);
}

export const setFilters = filters => ({ type: SET_FILTERS, filters });

export const resetFilters = () => ({ type: RESET_FILTERS });

// REDUCER HELPER METHODS
const dealsRequested = (state, query) => state.setIn(['queries', query, 'isLoading'], true);

const dealsReceived = (state, payload, query) =>
  state
    .setIn(['queries', query, 'items'], Immutable.fromJS(payload))
    .setIn(['queries', query, 'isLoading'], false)
    .setIn(['queries', query, 'isLoaded'], true);

const requestFailed = (state, query) =>
  state.setIn(['queries', query, 'isLoading'], false).setIn(['queries', query, 'isLoaded'], false);

const onSetFilters = (state, filters = Immutable.Map()) => {
  // console.log('onSetFilters', filters && filters.toJS());
  let newState = state.set(filters, initialFilters);
  filters.forEach((value, key) => {
    newState = newState.setIn(['filters', key], value).setIn(['activeFilters', key], value);
  });
  return newState;
};

const onResetFilters = state =>
  // AnalyticsService.track('Deals: Clear Filters');
  state.set('filters', initialFilters).set('activeFilters', initialFilters);
// REDUCER

export default function hotelDeals(state = initialState, action) {
  switch (action.type) {
    case REQUEST:
      return dealsRequested(state, action.meta.query);
    case SUCCESS:
      return dealsReceived(state, action.payload, action.meta.query);
    case FAILURE:
      return requestFailed(state, action.meta.queryString);
    case SET_FILTERS:
      return onSetFilters(state, action.filters);
    case RESET_FILTERS:
      return onResetFilters(state);
    default:
      return state;
  }
}

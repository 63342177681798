import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import classNames from 'classnames';

import Styled from './styled';

const ModalWrapper = ({
  modal,
  title,
  enableBackgroundClick,
  hideCloseButton,
  className,
  onModalClose,
  hideModal,
  overflow,
  noPadding,
  type,
  children,
  ...props
}) => {
  const modalRef = useRef();

  const closeModal = () => {
    onModalClose();
    hideModal();
  };

  const handleBackgroundClick = e => {
    const node = modalRef.current;
    if (!node.contains(e.target) && enableBackgroundClick) {
      closeModal();
    }
  };

  const handleModalClick = e => {
    if (e.target.className === 'close-button') hideModal();
  };

  const containerClass = classNames({}, modal.get('show') ? modal.get('animation') : null, className);
  const bodyClass = classNames('modal-body', {
    'modal-body-form': type === 'form',
  });

  return (
    <Styled id="modal-container" className={containerClass} overflow={overflow} noPadding={noPadding}>
      <div className="modal-background" onClick={e => handleBackgroundClick(e)}>
        <div {...props} className="modal" onClick={e => handleModalClick(e)} ref={modalRef}>
          {title && (
            <div className="modal-header">
              <span>{title}</span>
              <button type="button" className="modal-header-close" onClick={hideModal}>
                <span>+</span>
              </button>
            </div>
          )}
          <div className={bodyClass}>
            {children}
            {!hideCloseButton && !title && (
              <div className="modal-close" onClick={() => closeModal()}>
                +
              </div>
            )}
          </div>
          <div className="modal-footer">Modal footer</div>
        </div>
      </div>
    </Styled>
  );
};

ModalWrapper.defaultProps = {
  onModalClose: () => console.log(' default onModalClose'),
};

ModalWrapper.propTypes = {
  modal: PropTypes.instanceOf(Immutable.Map).isRequired,
  title: PropTypes.string,
  enableBackgroundClick: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
  onModalClose: PropTypes.func,
  hideModal: PropTypes.func.isRequired,
  overflow: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  noPadding: PropTypes.bool,
  type: PropTypes.string,
};

export default ModalWrapper;

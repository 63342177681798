import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import ModalWrapper from './ModalWrapper';

const Modal = ({ modal, hideModal }) => {
  if (!modal) {
    return null;
  }
  const modalProps = modal.get('props');
  const modalComponent = modal.get('component');
  const overflow = modalProps.overflow === 'scroll' ? 'scroll' : 'visible';
  // const noPadding = modalProps.noPadding;
  return (
    <ModalWrapper
      hideCloseButton={modalProps ? modalProps.hideCloseButton : false}
      className={modalProps ? modalProps.className : null}
      title={modalProps ? modalProps.title : null}
      modal={modal}
      onModalClose={modalProps ? modalProps.onModalClose : undefined}
      enableBackgroundClick
      hideModal={hideModal}
      overflow={overflow}
      type={modalProps.type}
      noPadding={modalProps.noPadding}
    >
      {modalComponent}
    </ModalWrapper>
  );
};

Modal.propTypes = {
  modal: PropTypes.instanceOf(Immutable.Map).isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default Modal;

import { CALL_API } from 'redux-api-middleware';
import Immutable from 'immutable';

import { BOOKING_API_ENDPOINT } from 'constants/endpoints';

// INITIAL STATE
const initialState = Immutable.Map();

// ACTION TYPES
const GET_BOOKINGS_REQUEST = 'skylark/bookings/GET_BOOKINGS_REQUEST';
const GET_BOOKINGS_SUCCESS = 'skylark/bookings/GET_BOOKINGS_SUCCESS';
const GET_BOOKINGS_FAILURE = 'skylark/bookings/GET_BOOKINGS_FAILURE';

const GET_BOOKING_DETAILS_REQUEST = 'skylark/bookings/GET_BOOKING_DETAILS_REQUEST';
const GET_BOOKING_DETAILS_SUCCESS = 'skylark/bookings/GET_BOOKING_DETAILS_SUCCESS';
const GET_BOOKING_DETAILS_FAILURE = 'skylark/bookings/GET_BOOKING_DETAILS_FAILURE';

// ACTIONS FROM OTHER DUCK
const AUTH_SIGN_OUT = 'skylark/authentication/SIGN_OUT';

// ACTION CREATOR HELPER METHODS

export const getUpcomingBookings = userId => ({
  [CALL_API]: {
    endpoint: `${BOOKING_API_ENDPOINT}/bookings.json?user_id=${userId}`,
    method: 'GET',
    types: [GET_BOOKINGS_REQUEST, GET_BOOKINGS_SUCCESS, GET_BOOKINGS_FAILURE],
  },
});

export const getBookingDetails = recordLocator => ({
  [CALL_API]: {
    endpoint: `${BOOKING_API_ENDPOINT}/bookings/${recordLocator}.json`,
    method: 'GET',
    types: [
      { type: GET_BOOKING_DETAILS_REQUEST, meta: { recordLocator } },
      { type: GET_BOOKING_DETAILS_SUCCESS, meta: { recordLocator } },
      { type: GET_BOOKING_DETAILS_FAILURE, meta: { recordLocator } },
    ],
    bailout: state => state.bookings.getIn(['items', recordLocator, 'details']),
  },
});

// REDUCER HELPER METHODS

const bookingsReceived = (payload, state) => {
  const bookings = Immutable.Map(
    Immutable.fromJS(payload).map(v => [v.get('record_locator'), v.set('details', false)])
  );
  return state
    .set('items', bookings)
    .set('loading', false)
    .set('loaded', true);
};

// REDUCER
const reducer = (state = initialState, action) => {
  const recordLocator = action.meta && action.meta.recordLocator ? action.meta.recordLocator : null;
  switch (action.type) {
    case GET_BOOKINGS_REQUEST:
      return state.set('loading', true).set('loaded', false);
    case GET_BOOKINGS_SUCCESS:
      return bookingsReceived(action.payload, state);
    case GET_BOOKINGS_FAILURE:
      return state.set('loading', false).set('loaded', false);
    case GET_BOOKING_DETAILS_REQUEST:
      return state.setIn(['items', recordLocator, 'loading'], true).setIn(['items', recordLocator, 'details'], true);
    case GET_BOOKING_DETAILS_SUCCESS:
      return state.setIn(
        ['items', recordLocator],
        Immutable.fromJS(action.payload)
          .set('details', true)
          .set('loaded', true)
      );
    case GET_BOOKING_DETAILS_FAILURE:
      return state.setIn(['items', recordLocator, 'loading'], false).setIn(['items', recordLocator, 'details'], false);
    case AUTH_SIGN_OUT:
      return initialState;
    default:
      return state;
  }
};

export default reducer;

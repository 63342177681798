// import moment from 'moment';
import Immutable from 'immutable';
import { CALL_API } from 'redux-api-middleware';
// import Cookies from 'js-cookie';

import { CONTENT_API_ENDPOINT } from 'constants/endpoints';

// import CREATE_MEMBERSHIP_SUCCESS from 'modules/membershipApplication';
const CREATE_MEMBERSHIP_REQUEST = 'skylark/membership/CREATE_MEMBERSHIP_REQUEST';
const CREATE_MEMBERSHIP_SUCCESS = 'skylark/membership/CREATE_MEMBERSHIP_SUCCESS';
const CREATE_MEMBERSHIP_FAILURE = 'skylark/membership/CREATE_MEMBERSHIP_FAILURE';

const GET_MEMBERSHIP_REQUEST = 'skylark/membership/GET_MEMBERSHIP_REQUEST';
const GET_MEMBERSHIP_SUCCESS = 'skylark/membership/GET_MEMBERSHIP_SUCCESS';
const GET_MEMBERSHIP_FAILURE = 'skylark/membership/GET_MEMBERSHIP_FAILURE';

const API_BASE = CONTENT_API_ENDPOINT;

const initialState = Immutable.Map({
  loading: false,
  loaded: false,
});

export function getMembership(userId) {
  return {
    [CALL_API]: {
      endpoint: `${API_BASE}/users/${userId}/membership`,
      method: 'GET',
      types: [GET_MEMBERSHIP_REQUEST, GET_MEMBERSHIP_SUCCESS, GET_MEMBERSHIP_FAILURE],
      bailout: state => state.membership.get('level'),
    },
  };
}

const setMembership = (state, payload) =>
  state
    .merge(Immutable.Map(payload))
    .set('loading', false)
    .set('loaded', true);

const membershipCreateFailure = (state, payload) =>
  state
    .set('status', 'error')
    .set('loading', false)
    .set('loaded', false)
    .set('errors', Immutable.fromJS(payload.response.errors));

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_MEMBERSHIP_REQUEST:
    case GET_MEMBERSHIP_REQUEST:
      return state.set('loading', true).set('loaded', false);
    case CREATE_MEMBERSHIP_SUCCESS:
    case GET_MEMBERSHIP_SUCCESS:
      return setMembership(state, action.payload);
    case GET_MEMBERSHIP_FAILURE:
      return state.set('loading', false).set('loaded', false);
    case CREATE_MEMBERSHIP_FAILURE:
      return membershipCreateFailure(state, action.payload);
    default:
      return state;
  }
};

export default reducer;

import moment from 'moment';
import { fromJS, List, Set } from 'immutable';

export const flightsFilterOptions = itineraries => {
  const airlines = itineraries
    .map(i => i.get('legs').map(l => l.get('airlines')))
    .flatten()
    .toSet();
  const stops = itineraries
    .map(i => i.get('max_stops'))
    .toSet()
    .sort();
  const outboundOrigins = itineraries.map(i => i.getIn(['legs', 0, 'origin'])).toSet();
  const outboundDestinations = itineraries.map(i => i.getIn(['legs', 0, 'destination'])).toSet();
  const returnOrigins = itineraries.map(i => i.getIn(['legs', 1, 'origin'])).toSet();
  const returnDestinations = itineraries.map(i => i.getIn(['legs', 1, 'destination'])).toSet();
  const outboundDuration = itineraries.map(i => i.getIn(['legs', 0, 'elapsed_time'])).sort();
  const returnDuration = itineraries.map(i => i.getIn(['legs', 1, 'elapsed_time'])).sort();
  const outboundDeparture = itineraries
    .map(i => i.getIn(['legs', 0, 'departs_at']))
    .sortBy(time => moment(time));
  const returnDeparture = itineraries
    .map(i => i.getIn(['legs', 1, 'departs_at']))
    .sortBy(time => moment(time));
  const arrivesAt = itineraries
    .map(i => i.getIn(['legs', 0, 'arrives_at']))
    .sortBy(time => moment(time));
  const offsetFloorTime = (time, offset) => {
    const midnight = moment.parseZone(time).startOf('day');
    const offsetTime = moment(time).subtract(offset, 'minutes');
    return offsetTime < midnight ? midnight : offsetTime;
  };

  const offsetCeilTime = (time, offset) => {
    const midnight = moment.parseZone(time).endOf('day');
    const offsetTime = moment(time).add(offset, 'minutes');
    return offsetTime > midnight ? midnight : offsetTime;
  };

  // Extend all ranges to make outer selections inclusive of all itineraries
  const outboundDurationRange = List([outboundDuration.first() - 10, outboundDuration.last() + 10]);

  const returnDurationRange = List([returnDuration.first() - 10, returnDuration.last() + 10]);

  const outboundDepartureRange = List([
    offsetFloorTime(outboundDeparture.first(), 10),
    offsetCeilTime(outboundDeparture.last(), 10),
  ]);

  const returnDepartureRange = List([
    offsetFloorTime(returnDeparture.first(), 10),
    offsetCeilTime(returnDeparture.last(), 10),
  ]);
  return fromJS({
    airlines,
    stops,
    arrivesAt,
    origins: outboundOrigins.merge(returnDestinations).toSet(),
    destinations: outboundDestinations.merge(returnOrigins).toSet(),
    outboundDuration: [outboundDurationRange.first(), outboundDurationRange.last()],
    returnDuration: [returnDurationRange.first(), returnDurationRange.last()],
    outboundDeparture: [outboundDepartureRange.first(), outboundDepartureRange.last()],
    returnDeparture: [returnDepartureRange.first(), returnDepartureRange.last()],
  });
};

const hasAirlinesPartial = (itinerary, airlines) => {
  // Returns true if there is a match for any Leg
  if (!itinerary) {
    return false;
  }
  const included = itinerary
    .get('legs')
    .map(l => l.get('airlines'))
    .flatten()
    .toSet();
  const interection = airlines.intersect(included);
  return !interection.isEmpty();
};

export const filterAirlines = (itineraries, airlines) => {
  const filteredAirlines = airlines ? airlines.toSet() : Set();
  const filtered = itineraries.filter(itinerary => hasAirlinesPartial(itinerary, filteredAirlines));
  const results = filteredAirlines.isEmpty() ? itineraries : filtered;
  return results.map(i => i.get('key')).toSet();
};

export const filterAirports = (itineraries, airports, endpoint) => {
  const filteredAirports = airports.toSet();
  const filtered = itineraries.filter(itinerary =>
    filteredAirports.includes(itinerary.get(endpoint))
  );
  const results = filteredAirports.isEmpty() ? itineraries : filtered;
  return results.map(i => i.get('key')).toSet();
};

export const filterStops = (itineraries, stops) => {
  const filteredStops = stops.toSet();
  const filtered = itineraries.filter(itinerary => stops.includes(itinerary.get('max_stops')));
  const results = filteredStops.isEmpty() ? itineraries : filtered;
  return results.map(i => i.get('key')).toSet();
};

const withinRange = (itinerary, range, leg) => {
  const aboveMin = itinerary.getIn(['legs', leg, 'elapsed_time']) > range.min();
  const belowMax = itinerary.getIn(['legs', leg, 'elapsed_time']) < range.max();
  return aboveMin && belowMax;
};

const withinTimeRange = (itinerary, range, leg) => {
  const aboveMin =
    moment.parseZone(itinerary.getIn(['legs', leg, 'departs_at'])) > moment.parseZone(range.min());
  const belowMax =
    moment.parseZone(itinerary.getIn(['legs', leg, 'departs_at'])) < moment.parseZone(range.max());
  return aboveMin && belowMax;
};

export const filterTimeRange = (itineraries, range, leg) => {
  const immutableRange = List(range);
  const filtered = itineraries.filter(itinerary => withinTimeRange(itinerary, immutableRange, leg));
  const results = range.isEmpty() ? itineraries : filtered;
  return results.map(i => i.get('key')).toSet();
};

export const filterDuration = (itineraries, range, leg) => {
  const immutableRange = List(range);
  const filtered = itineraries.filter(itinerary => withinRange(itinerary, immutableRange, leg));
  const results = range.isEmpty() ? itineraries : filtered;
  return results.map(i => i.get('key')).toSet();
};

export const filterArrivalDates = (itineraries, arrivalDate) => {
  const filtered = itineraries.filter(itinerary => {
    const arrivesAt = itinerary.getIn(['legs', 0, 'arrives_at']);
    return arrivalDate.includes(arrivesAt);
  });
  return filtered.map(i => i.get('key'));
};

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import delay from 'lodash/delay';
import { closeModal } from 'modules/modals';

import Modal from './Modal';

const activateDomModal = () => {
  document.querySelector('body').classList.add('modal-active');
  document.querySelector('body').classList.remove('out');
};

const deactivateDomModal = () => {
  const body = document.querySelector('body');
  const container = document.querySelector('#modal-container');
  body.classList.remove('modal-active');
  if (container) {
    container.classList.add('out');
  }
};

const ModalConductor = () => {
  const dispatch = useDispatch();
  const modal = useSelector(state => state.modals);
  const isVisible = modal && modal.get('show');

  const onModalClose = () => {
    deactivateDomModal();
    delay(() => dispatch(closeModal()), 250);
  };

  if (isVisible) {
    activateDomModal();
    return <Modal hideModal={onModalClose} modal={modal} />;
  }
  return null;
};

export default ModalConductor;

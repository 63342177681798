import Immutable from 'immutable';

const initialState = Immutable.Map();

const SET_SCROLL_POSITION = 'skylark/scrollPosition/SET_SCROLL_POSITION';

export const setScrollPosition = (position, pathname) => ({ type: SET_SCROLL_POSITION, position, pathname });

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SCROLL_POSITION:
      return state.set(action.pathname, action.position);
    default:
      return state;
  }
};

export default reducer;

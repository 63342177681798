import Immutable from 'immutable';
import moment from 'moment';
import queryString from 'query-string';
import { CALL_API } from 'redux-api-middleware';
import { PRICING_API_ENDPOINT } from 'constants/endpoints';

const initialState = Immutable.Map();

const GET_AIRFARE_REQUEST = 'skylark/airfare/GET_AIRFARE_REQUEST';
const GET_AIRFARE_SUCCESS = 'skylark/airfare/GET_AIRFARE_SUCCESS';
const GET_AIRFARE_FAILURE = 'skylark/airfare/GET_AIRFARE_FAILURE';

const createSegmentsIndex = segments => {
  let segmentsIndex = Immutable.Map();
  segments.forEach(segment => {
    const immutableSegment = Immutable.fromJS(segment);
    segmentsIndex = segmentsIndex.set(immutableSegment.get('key'), immutableSegment);
  });
  return segmentsIndex;
};

const setKeyedItineraries = payload => {
  let keyedItineraries = Immutable.Map();
  payload.get('itineraries').forEach(itin => {
    const key = itin.get('key');
    if (!keyedItineraries.get(key)) {
      keyedItineraries = keyedItineraries.set(key, itin);
    }
  });
  return payload.set('itineraries', keyedItineraries.toList());
};

const getRequestKey = (origin, destination, departDate, arriveDate, returnDate, cabinClass, travelerCount) => {
  const departDateKey = departDate && moment(departDate).format('YYYYMMDD');
  const arriveDateKey = arriveDate && moment(arriveDate).format('YYYYMMDD');
  const returnDateKey = returnDate && moment(returnDate).format('YYYYMMDD');

  return arriveDate
    ? `${origin}|${destination}|A${arriveDateKey}|${returnDateKey}|${cabinClass}|${travelerCount}`
    : `${origin}|${destination}|${departDateKey}|${returnDateKey}|${cabinClass}|${travelerCount}`;
};

const buildRequest = (origin, destination, departDate, arriveDate, returnDate, cabinClass, travelerCount) => {
  const baseRequest = {
    origin,
    destination,
    returns_at: moment(returnDate).format('YYYY-MM-DD'),
    cabin: cabinClass,
    passengers: travelerCount,
    request_key: getRequestKey(origin, destination, departDate, arriveDate, returnDate, cabinClass, travelerCount),
  };

  return arriveDate
    ? Object.assign(baseRequest, { arrives_at: moment(arriveDate).format('YYYY-MM-DD') })
    : Object.assign(baseRequest, { departs_at: moment(departDate).format('YYYY-MM-DD') });
};

const getQuery = (origin, destination, departDate, arriveDate, returnDate, cabinClass, travelerCount) => {
  const request = buildRequest(origin, destination, departDate, arriveDate, returnDate, cabinClass, travelerCount);
  return queryString.stringify(Object.assign(request, { results: 100 }));
};

const getMeta = (origin, destination, departDate, arriveDate, returnDate, cabinClass, travelerCount) => {
  const request = buildRequest(origin, destination, departDate, arriveDate, returnDate, cabinClass, travelerCount);
  const requestKey = getRequestKey(origin, destination, departDate, arriveDate, returnDate, cabinClass, travelerCount);
  return Object.assign(request, { request_key: requestKey });
};

export const getAirfareRequest = (origin = 'NYC', destination, departDate, returnDate, cabinClass, travelerCount) => {
  if (!departDate || !returnDate || !origin) {
    // console.log('Cannot get airfare request without dates');
    return null;
  }
  const query = getQuery(origin, destination, departDate, undefined, returnDate, cabinClass, travelerCount);
  const meta = getMeta(origin, destination, departDate, undefined, returnDate, cabinClass, travelerCount);
  const requestKey = meta.request_key;
  return {
    [CALL_API]: {
      endpoint: `${PRICING_API_ENDPOINT}/airfares?${query}`,
      bailout: state => !!state.airfare.get(requestKey),
      method: 'GET',
      types: [
        {
          type: GET_AIRFARE_REQUEST,
          meta,
        },
        {
          type: GET_AIRFARE_SUCCESS,
          meta,
        },
        {
          type: GET_AIRFARE_FAILURE,
          meta,
        },
      ],
    },
  };
};

export const getArrivalAirfareRequest = (
  origin = 'NYC',
  destination,
  arrivalDate,
  returnDate,
  cabinClass,
  travelerCount
) => {
  if (!arrivalDate || !returnDate || !origin) {
    console.log('Cannot get airfare request without dates');
    return null;
  }

  const query = getQuery(origin, destination, undefined, arrivalDate, returnDate, cabinClass, travelerCount);
  const meta = getMeta(origin, destination, undefined, returnDate, arrivalDate, cabinClass, travelerCount);
  const requestKey = meta.request_key;
  return {
    [CALL_API]: {
      endpoint: `${PRICING_API_ENDPOINT}/airfares?${query}`,
      bailout: state => !!state.airfare.get(requestKey),
      method: 'GET',
      types: [
        {
          type: GET_AIRFARE_REQUEST,
          meta,
        },
        {
          type: GET_AIRFARE_SUCCESS,
          meta,
        },
        {
          type: GET_AIRFARE_FAILURE,
          meta,
        },
      ],
    },
  };
};

const reducer = (state = initialState, action) => {
  let fareKey;
  switch (action.type) {
    case GET_AIRFARE_REQUEST:
      return state.setIn([action.meta.request_key, 'isLoading'], true);
    case GET_AIRFARE_SUCCESS:
      fareKey = action.meta ? action.meta.request_key : action.payload.request_key;
      return state
        .set(fareKey, setKeyedItineraries(Immutable.fromJS(action.payload)))
        .setIn([fareKey, 'isLoaded'], true)
        .setIn([fareKey, 'segments'], createSegmentsIndex(action.payload.flight_segments));
    case GET_AIRFARE_FAILURE:
      fareKey = action.meta ? action.meta.request_key : action.payload.request_key;
      return state.setIn([fareKey, 'isLoading'], false).setIn([fareKey, 'error'], action.payload);
    default:
      return state;
  }
};

export default reducer;

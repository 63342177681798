/* eslint-disable react/jsx-props-no-spreading */
// pages/_app.js
import PropTypes from 'prop-types';
import React from 'react';
import { wrapper } from 'lib/configureStore';
import Router from 'next/router';
import NProgress from 'nprogress';

import UserAgentProvider from 'lib/UserAgent/Provider';
import { UtmTrackingProvider } from 'lib/UtmTracking/';
import { ThemeProvider } from 'styled-components';
import theme from 'config/theme';
import GlobalStyle from 'config/theme/globalStyle';

import PageWrapper from 'components/PageWrapper';
import { Favicon } from 'components/UI';
import ModalConductor from 'components/Modals/ModalConductor';

import 'scss/index.scss';

// Comment to force deploy

NProgress.configure({ showSpinner: false });
Router.events.on('routeChangeStart', () => {
  NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const MyApp = ({ Component, pageProps, userAgent }) => {
  // console.log('MyApp > Router > router', Router?.router)
  // console.log('MyApp > pageProps', pageProps);
  return (
    <>
      <Favicon />
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <UtmTrackingProvider query={Router?.router?.query}>
          <UserAgentProvider userAgent={pageProps.userAgent || userAgent}>
            <PageWrapper
              layout={pageProps?.layout}
              trackingOptions={pageProps?.trackingOptions}
              layoutOptions={pageProps?.layoutOptions || Component?.layout}
            >
              <Component {...pageProps} />
              <ModalConductor />
            </PageWrapper>
          </UserAgentProvider>
        </UtmTrackingProvider>
      </ThemeProvider>
    </>
  );};

MyApp.propTypes = {
  Component: PropTypes.func,
  pageProps: PropTypes.shape({
    layout: PropTypes.instanceOf(Object),
    trackingOptions: PropTypes.instanceOf(Object),
  }),
  store: PropTypes.any,
  userAgent: PropTypes.string,
};

export default wrapper.withRedux(MyApp);

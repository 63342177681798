import Immutable from 'immutable';

export const initialState = Immutable.fromJS({
  loading: false,
  loaded: false,
  items: {},
});

// ACTION TYPES
const REQUEST_COLLECTION_SUCCESS = 'skylark/collections/REQUEST_COLLECTION_SUCCESS';
const REQUEST_DESTINATION_SUCCESS = 'skylark/destination/SUCCESS';

// REDUCER HELPER METHODS

// Convert Hotels Array into code keyed, Immutable Map
const createHotelsMap = hotels => {
  if (!hotels) {
    return null;
  }
  return Immutable.Map(hotels.map(hotel => [hotel.get('code'), hotel.set('object_type', 'hotel')]));
};

const collectionReceived = (collection, state) => {
  const hotels = createHotelsMap(Immutable.fromJS(collection).get('hotels'));
  return state.mergeIn(['items'], hotels);
};

const destinationReceived = (destination, state) => {
  const hotels = createHotelsMap(Immutable.fromJS(destination).get('hotels'));
  return state.mergeIn(['items'], hotels);
};

// REDUCER
export default function reducer(state = initialState, action = {}) {
  // const code = action.meta ? action.meta.code : null;
  switch (action.type) {
    case REQUEST_COLLECTION_SUCCESS:
      return collectionReceived(action.payload, state);
    case REQUEST_DESTINATION_SUCCESS:
      return destinationReceived(action.payload, state);
    default:
      return state;
  }
}

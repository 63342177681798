import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { getSearchInfo, getPageViewType } from 'lib/trackingHelpers';
// import { Router, useRouter } from 'next/router';
import { useTracking } from 'react-tracking';
import { useAnalytics, useSessionStorage } from 'hooks';
import Events from 'constants/events';

const getHistory = pageHistory => {
  const history = Array.isArray(pageHistory) ? pageHistory : [];
  const previousUrl = history[history.length - 1];
  return {
    history,
    previousUrl,
  };
};

const PageTracking = ({ url, trackingOptions }) => {
  // const router = useRouter();
  const tracking = useTracking();
  const { analytics, trackPage } = useAnalytics();
  const [pageHistory, setPageHistory] = useSessionStorage('session_page_history', []);

  useEffect(() => {
    const query = queryString.parse(window.location.search);
    const pageViewType = getPageViewType(query);
    const isSearch = pageViewType === 'search' || pageViewType === 'bundle';
    const search = isSearch ? getSearchInfo(query) : null;
    const derivedTrackingParams = { page_variant: pageViewType, search };
    const trackingParams = Object.assign(derivedTrackingParams, trackingOptions);
    // console.log('PageTracking > trackingParams', trackingParams);

    const pageName = trackingParams?.page_name || 'Unknown Page';
    window.analytics.page(pageName, { source: 'next', env: process.env.NODE_ENV, url, ...trackingParams });
  }, [url]);

  useEffect(() => {
    // console.log('PageTracking > useEffect (url, analytics)');
    if (analytics) {
      const { history, previousUrl } = getHistory(pageHistory);
      const urlChanged = previousUrl !== url;

      if (urlChanged) {
        setPageHistory(history.concat([url]));
      }

      const isLandingTab = pageHistory.length < 1;
      if (isLandingTab && urlChanged) {
        tracking.trackEvent({
          event: Events.SITE_VISIT,
          properties: trackingOptions || {},
        });
      }
    }
  }, [url, analytics]);

  // return <React.Fragment>{children}</React.Fragment>;
  return null;
};

PageTracking.propTypes = {
  trackingOptions: PropTypes.instanceOf(Object),
  // children: PropTypes.node,
};

export default PageTracking;

import Immutable from 'immutable';

import airlinesList from 'constants/airlines.json';

const GET_AIRFARE_SUCCESS = 'skylark/airfare/GET_AIRFARE_SUCCESS';

// console.log('Airlines List', airlinesList)

export const initialState = Immutable.Map(Immutable.fromJS(airlinesList).map(v => [v.get('code'), v]));

const reducer = (state = initialState, action) => {
  let newState = Immutable.Map();
  switch (action.type) {
    case GET_AIRFARE_SUCCESS:
      action.payload.airlines.forEach(airline => {
        newState = newState.set(airline.code, Immutable.fromJS(airline));
      });
      return newState;
    default:
      return state;
  }
};

export default reducer;

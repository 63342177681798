import Immutable from 'immutable';
import { CALL_API } from 'redux-api-middleware';

import { CONTENT_API_ENDPOINT, BLOG_ENDPOINT } from 'constants/endpoints';

const itemsSelector = items => {
  const linkUrl = item => {
    switch (item.get('content_type')) {
      case 'blog_article':
        return `${BLOG_ENDPOINT}/articles/${item.get('slug')}`;
      case 'hotel':
        return `/hotels/${item.get('hotel_code')}`;
      case 'hotel_collection':
        return `/collections/${item.get('content_id') || item.get('id')}`;
      default:
        return '';
    }
  };

  const itemCategories = {
    blog_article: 'Articles',
    hotel: 'Featured Hotels',
    hotel_collection: 'Inspired Collections',
  };

  const itemHeading = item => {
    switch (item.get('content_type')) {
      case 'blog_article':
        return item.get('category_name');
      case 'hotel':
        return item.get('location_description');
      default:
        return '';
    }
  };

  return items.map(item =>
    item
      .set('linkUrl', linkUrl(item))
      .set('category', itemCategories[item.get('content_type')])
      .set('heading', itemHeading(item))
  );
};

export const fetchFeaturedItems = async () => {
  const resourceUrl = `${CONTENT_API_ENDPOINT}/featured_content_items`;

  const res = await fetch(resourceUrl);
  const data = Immutable.fromJS(await res.json());

  const all = itemsSelector(data);
  const carousel = all.filter(i => i.get('display_type') === 'carousel').toJS();
  const featured = itemsSelector(data)
    .filter(i => i.get('display_type') !== 'carousel')
    .toJS();
  return { all, featured, carousel };
};

const GET_FEATURED_ITEMS_REQUEST = 'skylark/featuredContentItems/GET_FEATURED_ITEMS_REQUEST';
const GET_FEATURED_ITEMS_SUCCESS = 'skylark/featuredContentItems/GET_FEATURED_ITEMS_SUCCESS';
const GET_FEATURED_ITEMS_FAILURE = 'skylark/featuredContentItems/GET_FEATURED_ITEMS_FAILURE';

const initialState = Immutable.fromJS({
  items: [],
});

export const getFeaturedItems = () => ({
  [CALL_API]: {
    endpoint: `${CONTENT_API_ENDPOINT}/featured_content_items`,
    method: 'GET',
    types: [GET_FEATURED_ITEMS_REQUEST, GET_FEATURED_ITEMS_SUCCESS, GET_FEATURED_ITEMS_FAILURE],
  },
});

const setItems = (payload, state) => state.set('items', Immutable.fromJS(payload));

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FEATURED_ITEMS_REQUEST:
      return state;
    case GET_FEATURED_ITEMS_SUCCESS:
      return setItems(action.payload, state);
    case GET_FEATURED_ITEMS_FAILURE:
      return state;
    default:
      return state;
  }
};

export default reducer;

import styled from 'styled-components';
import { colors, toRgba, variables } from 'styles';

const Styled = styled.div`
  position: fixed;
  display: table;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transform: scale(0);
  z-index: 19;

  form {
    min-height: 100%;
    border-radius: 3px;
  }

  .flex-form {
    padding: 0;
  }

  .modal-footer {
    display: none;
    padding: 5px;
  }

  .modal-body {
    padding: ${props => (props.noPadding ? '0' : '1rem 30px 30px')};
    font-weight: 300;
    min-width: 450px;
    max-height: 60vh;
    overflow: ${props => props.overflow};
    /* border: 1px dashed magenta; */
    &.modal-body-form {
      padding: 1rem;
    }
    @media (max-width: ${variables.screen.xs}) {
      min-width: 100%;
      max-height: 100vh;
    }
  }

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    background: ${colors.paleGray};
    border-bottom: 1px solid ${colors.warmGray};
    border-radius: 3px 3px 0 0;
    padding: 0.5rem 0.5rem 0.5rem 0.75rem;
    .modal-header-close {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        height: 20px;
        width: 20px;
        line-height: 20px;
        font-size: 26px;
        font-weight: normal;
        cursor: pointer;
        transform: rotate(45deg);
      }
    }
    @media (max-width: ${variables.screen.xs}) {
      border-radius: 0;
    }
  }
  .modal-footer {
    /* border: 1px dashed orange; */
  }

  .modal-background {
    display: table-cell;
    background: ${toRgba(colors.black, 0.65)};
    text-align: center;
    /* vertical-align: middle; */
    padding-top: 20vh;

    .modal {
      border-radius: 3px;
      background: ${colors.white};
      display: inline-block;

      text-align: left;
      position: relative;

      &.modal-lean {
        background: transparent;
        padding: 0;
      }
      .modal-close {
        cursor: pointer;
        border-radius: 50%;
        color: $black;
        text-align: center;
        position: absolute;
        right: 10px;
        top: 7px;
        font-size: 36px;
        font-weight: 500;
        line-height: 25px;
        z-index: 99;
        cursor: pointer;
        transform: rotate(45deg);
        transition: all 0.25s;
        &:hover {
          color: ${colors.negRed};
          text-shadow: 0 2px 2px ${toRgba(colors.black, 0.05)};
          transform: rotate(135deg) scale(1.2, 1.2);
        }
      }

      @media (max-width: ${variables.screen.xs}) {
        min-width: auto;
        position: absolute;
        top: 0px;
        right: 0px;
        left: 0px;
        bottom: 0px;
        width: 100% !important;
        border-radius: 0;
      }

      h1 {
        margin-top: 0;
      }
      h2 {
        font-size: 25px;
        line-height: 25px;
        margin-bottom: 15px;
      }
      p {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }

  &.slide-up {
    transform: scale(1);
    .modal-background {
      background: rgba(0, 0, 0, 0);
      animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
      .modal {
        opacity: 0;
        animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
      }
    }
    + .content {
      animation: scaleBack 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }
    &.out {
      animation: quickScaleDown 0s 0.5s linear forwards;
      .modal-background {
        animation: fadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
        .modal {
          animation: scaleDown 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
        }
      }
      + .content {
        animation: scaleForward 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
      }
    }
  }

  &.zoom-in {
    z-index: 19;
    transform: scale(1);
    .modal-background {
      background: rgba(0, 0, 0, 0.65);
      .modal {
        animation: blowUpModal 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
      }
    }
    + .content {
      z-index: 1;
      animation: blowUpContent 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }
    &.out {
      .modal-background {
        animation: fadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
        .modal {
          animation: blowUpModalTwo 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
        }
      }
      + .content {
        animation: blowUpContentTwo 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
      }
    }
  }

  @keyframes fadeIn {
    0% {
      background: rgba(0, 0, 0, 0);
    }
    100% {
      background: rgba(0, 0, 0, 0.7);
    }
  }

  @keyframes fadeOut {
    0% {
      background: rgba(0, 0, 0, 0.7);
    }
    100% {
      background: rgba(0, 0, 0, 0);
    }
  }

  @keyframes scaleUp {
    0% {
      transform: scale(0.8) translateY(1000px);
      opacity: 0;
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
    }
  }

  @keyframes scaleDown {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 1;
    }
    100% {
      transform: scale(0.8) translateY(1000px);
      opacity: 0;
    }
  }

  @keyframes scaleBack {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.85);
    }
  }

  @keyframes scaleForward {
    0% {
      transform: scale(0.85);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes quickScaleDown {
    0% {
      transform: scale(1);
    }
    99.9% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }

  @keyframes blowUpContent {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    99.9% {
      transform: scale(2);
      opacity: 0;
    }
    100% {
      transform: scale(0);
    }
  }

  @keyframes blowUpContentTwo {
    0% {
      transform: scale(2);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes blowUpModal {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes blowUpModalTwo {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0);
      opacity: 0;
    }
  }
`;

export default Styled;

import Immutable from 'immutable';
import moment from 'moment';

// INITIAL STATE
export const initialState = Immutable.fromJS({
  activatedAt: null,
});

// ACTION TYPES
const ACTIVATE = 'skylark/workflow/activation/ACTIVATE';

// ACTION CREATORS
export function activate() {
  return { type: ACTIVATE };
}

// REDUCER HELPER METHODS
export const markActivated = state => state.set('activatedAt', moment());

// REDUCER
export default function reducer(state = initialState, action = {}) {
  // const code = action.meta ? action.meta.code : null;
  switch (action.type) {
    case ACTIVATE:
      return markActivated(state);
    default:
      return state;
  }
}

import Immutable from 'immutable';
import { flightsFilterOptions } from 'selectors/flightsFilterService';

const SELECT_ITINERARY = 'skylark/flightFilter/SELECT_ITINERARY';
const INITIALIZE_FLIGHTS_FILTER = 'skylark/flightFilter/INITIALIZE_FLIGHTS_FILTER';
const FLIGHTS_FILTER_SET_AIRLINE = 'skylark/flightFilter/FLIGHTS_FILTER_SET_AIRLINE';
const FLIGHTS_FILTER_SET_DESTINATION = 'skylark/flightFilter/FLIGHTS_FILTER_SET_DESTINATION';
const FLIGHTS_FILTER_SET_ORIGIN = 'skylark/flightFilter/FLIGHTS_FILTER_SET_ORIGIN';
const FLIGHTS_FILTER_SET_STOP = 'skylark/flightFilter/FLIGHTS_FILTER_SET_STOP';
const FLIGHTS_FILTER_SET_OUTBOUND_DEPARTURE = 'skylark/flightFilter/FLIGHTS_FILTER_SET_OUTBOUND_DEPARTURE';
const FLIGHTS_FILTER_SET_RETURN_DEPARTURE = 'skylark/flightFilter/FLIGHTS_FILTER_SET_RETURN_DEPARTURE';
const FLIGHTS_FILTER_SET_OUTBOUND_DURATION = 'skylark/flightFilter/FLIGHTS_FILTER_SET_OUTBOUND_DURATION';
const FLIGHTS_FILTER_SET_RETURN_DURATION = 'skylark/flightFilter/FLIGHTS_FILTER_SET_RETURN_DURATION';
const PRESELECT_ITINERARY = 'skylark/flightFilter/PRESELECT_ITINERARY';
const FILTER_ARRIVAL_DATES = 'skylark/flightFilter/FILTER_ARRIVAL_DATES';

export const initializeFlightsFilter = itineraries => ({ type: INITIALIZE_FLIGHTS_FILTER, itineraries });
export const setAirline = (airlineCode, exclusive) => ({
  type: FLIGHTS_FILTER_SET_AIRLINE,
  code: airlineCode,
  exclusive,
});

export const setOrigin = (airportCode, exclusive) => ({
  type: FLIGHTS_FILTER_SET_ORIGIN,
  code: airportCode,
  exclusive,
});

export const setDestination = (airportCode, exclusive) => ({
  type: FLIGHTS_FILTER_SET_DESTINATION,
  code: airportCode,
  exclusive,
});

export const setStop = (count, exclusive) => ({ type: FLIGHTS_FILTER_SET_STOP, code: count, exclusive });

export const setOutboundDeparture = range => ({ type: FLIGHTS_FILTER_SET_OUTBOUND_DEPARTURE, range });

export const setReturnDeparture = range => ({ type: FLIGHTS_FILTER_SET_RETURN_DEPARTURE, range });

export const setOutboundDuration = range => ({ type: FLIGHTS_FILTER_SET_OUTBOUND_DURATION, range });

export const setReturnDuration = range => ({ type: FLIGHTS_FILTER_SET_RETURN_DURATION, range });

export const preSelectItinerary = itinerary => ({ type: PRESELECT_ITINERARY, itinerary });

export const selectItinerary = itinerary => ({ type: SELECT_ITINERARY, itinerary });

export const filterArrivalDates = arrivesAt => ({ type: FILTER_ARRIVAL_DATES, code: arrivesAt });

const setValuesFilter = (state, action, key) => {
  const origins = state.getIn(['selections', key]);
  const isSelected = origins.includes(action.code);
  let nextValues = isSelected ? origins.filter(a => a !== action.code) : origins.add(action.code);

  if (action.exclusive) {
    nextValues = Immutable.Set([action.code]);
  } else if (action.code === 'ALL') {
    nextValues = state.getIn(['options', key]);
  }
  return state.setIn(['selections', key], nextValues);
};

const setArrivalDates = (state, action, key) => {
  const origins = state.getIn(['options', key]);
  // const isSelected = origins.includes(action.code);
  // let nextValues = isSelected ? origins.filter(a => a.isSame(action.code)) : origins.add(action.code);
  let nextValues = null;
  if (action.code) {
    nextValues = origins.filter(a => {
      const arrivalDate = `${a.month()}/${a.date()}`;
      const selectedArrivalDate = `${action.code.month()}/${action.code.date()}`;
      return arrivalDate === selectedArrivalDate;
    });
  } else {
    nextValues = origins;
  }
  return state.setIn(['selections', key], nextValues);
};

const reducer = (state = Immutable.Map(), action) => {
  switch (action.type) {
    case INITIALIZE_FLIGHTS_FILTER:
      if (!action.itineraries) {
        return state;
      }
      return state
        .set('options', flightsFilterOptions(action.itineraries))
        .set('selections', flightsFilterOptions(action.itineraries));
    case FLIGHTS_FILTER_SET_AIRLINE:
      return setValuesFilter(state, action, 'airlines');
    case FLIGHTS_FILTER_SET_ORIGIN:
      return setValuesFilter(state, action, 'origins');
    case FLIGHTS_FILTER_SET_DESTINATION:
      return setValuesFilter(state, action, 'destinations');
    case FLIGHTS_FILTER_SET_STOP:
      return setValuesFilter(state, action, 'stops');
    case FLIGHTS_FILTER_SET_OUTBOUND_DEPARTURE:
      return state.setIn(['selections', 'outboundDeparture'], Immutable.Set(action.range));
    case FLIGHTS_FILTER_SET_RETURN_DEPARTURE:
      return state.setIn(['selections', 'returnDeparture'], Immutable.Set(action.range));
    case FLIGHTS_FILTER_SET_OUTBOUND_DURATION:
      return state.setIn(['selections', 'outboundDuration'], Immutable.Set(action.range));
    case FLIGHTS_FILTER_SET_RETURN_DURATION:
      return state.setIn(['selections', 'returnDuration'], Immutable.Set(action.range));
    case FILTER_ARRIVAL_DATES:
      return setArrivalDates(state, action, 'arrivesAt');
    default:
      return state;
  }
};

export default reducer;

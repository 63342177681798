import Immutable from 'immutable';
import { CALL_API } from 'redux-api-middleware';

const CONFIG_DATA_URL = 'https://gateway.skylark.com/api/v3/settings';

const GET_CONFIG_REQUEST = 'skylark/siteConfig/GET_CONFIG_REQUEST';
const GET_CONFIG_SUCCESS = 'skylark/siteConfig/GET_CONFIG_SUCCESS';
const GET_CONFIG_FAILURE = 'skylark/siteConfig/GET_CONFIG_FAILURE';

const initialState = Immutable.fromJS({
  featureFlags: {},
});

const getCurrentEnvironment = () => {
  const enviromnent = {
    'skylark.com': 'production',
    'staging.skylark.com': 'staging',
    localhost: 'development',
  };
  return enviromnent[window.location.hostname] || 'development';
};

const setSiteConfig = (state, payload) => {
  const currentEnv = getCurrentEnvironment();
  console.log('CONFIG PAYLOAD', payload);
  const environmentFeatures = payload[currentEnv];
  const featureFlags = Immutable.fromJS(environmentFeatures);
  console.log('featureFlags', featureFlags && featureFlags.toJS());
  return state.set('featureFlags', featureFlags);
};

// export async function intializeSiteConfig() {
//   const url = `${CONFIG_DATA_URL}`;
//   const response = await fetch(url);
//   const json = await response.json();

//   const currentEnv = getCurrentEnvironment();
//   const featureFlags = Immutable.fromJS(json[currentEnv]);

//   console.log('JSON', json);
//   // console.log('featureFlags', featureFlags && featureFlags.toJS());
//   return featureFlags;
// }

// export function setFeatureFlags() {
//   const featureFlags = intializeSiteConfig();
//   console.log('featureFlags', featureFlags && featureFlags.toJS());
//   return { type: SET_FEATURE_FLAGS, featureFlags };
// }

export const intializeSiteConfig = () => ({
  [CALL_API]: {
    endpoint: CONFIG_DATA_URL,
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'cache-control': 'no-cache' },
    // mode: 'no-cors',
    types: [GET_CONFIG_REQUEST, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
  },
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONFIG_REQUEST:
      return state;
    case GET_CONFIG_SUCCESS:
      return setSiteConfig(state, action.payload);
    case GET_CONFIG_FAILURE:
      return state;
    default:
      return state;
  }
};

export default reducer;
